import { HostListener, Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDateMask]',
  standalone: true,
})
export class DateMaskDirective {
  @Input() appDateMask = false;

  constructor(private el: ElementRef) {}

  @HostListener('input')
  onInput() {
    if (this.appDateMask) {
      const inputValue = this.el.nativeElement.value;
      const formattedValue = this.formatDate(inputValue);

      this.el.nativeElement.value = formattedValue;
    }
  }

  private formatDate(value: string): string {
    // checks if the length of the input value is less than or equal to 2 characters.
    // If so, it means the value represents either a day or a month, and returns the value as is
    if (value.length <= 2) {
      return value;
    }

    // checks if the length of the value is exactly 3 characters and if it contains a slash (/)
    if (value.length == 3 && value.includes('/')) {
      // means the value is already formatted correctly (e.g., "07/"), so return the value as is.
      return value;
    } else if (value.length == 3) {
      // assumes the value represents a combination of month and day without a slash (e.g., "071")
      // and formats it by splitting it into month and day components and adding a slash in between.
      const month = value.slice(0, 2);
      const day = value.slice(2);
      return `${month}/${day}`;
    }

    if (value.length >= 6) {
      // checks if the 5th character is already a slash (zero index)
      if (value.charAt(5) === '/') {
        // means the value is already formatted correctly (e.g., "07/14/")
        return value;
      } else {
        if (value.includes('/')) {
          // assumes the value represents a combination of month, day, and year without the second slash (e.g., "07/141")
          // and formats it by splitting it and adding the slash accordingly
          const monthAndDay = value.slice(0, 5);
          const year = value.slice(5);
          return `${monthAndDay}/${year}`;
        } else {
          // If the value doesn't contain any slashes,
          // it assumes the date was entered automatically via autofill (e.g., "07141989") and formats it by
          // splitting it into month, day, and year components and adding slashes in between.
          const month = value.slice(0, 2);
          const day = value.slice(2, 4);
          const year = value.slice(4, 8);
          return `${month}/${day}/${year}`;
        }
      }
    }

    return value;
  }
}
