import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]',
  standalone: true,
})
export class PhoneMaskDirective {
  @Input() appPhoneMask = false;
  private readonly PHONE_MASK = /(\d{3})(\d{3})(\d{4})/;

  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event'])
  onBlur(event: Event) {
    if (this.appPhoneMask) {
      const input = this.el.nativeElement;
      let value = input.value.replace(/\D/g, '');

      if (!value) {
        input.value = '';
        return;
      }

      // Remove 1- prefix if present
      if (value.length === 11 && value.startsWith('1')) {
        value = value.slice(1);
      }

      const valid = this.PHONE_MASK.test(value);

      if (valid) {
        input.value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6, 10);
      } else {
        input.value = value.slice(0, 10).replace(this.PHONE_MASK, '$1-$2-$3');
      }

      input.dispatchEvent(new Event('input', { bubbles: true }));

      if (input.value !== value) {
        event.stopPropagation();
      }
    }
  }
}
