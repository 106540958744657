<main class="container py-4 px-3" [appInert]="showTermsAndPrivacyModal">
  <header>
    <h1 class="text-primary mb-4">
      {{ "APPOINTMENT.RESERVATION.TITLE" | translate }}
    </h1>

    <!-- <p class="text-tertiary mt-4 mb-2">
      {{ 'APPOINTMENT.SEARCH.SUBTITLE' | translate }}
    </p>
    <h4 class="text-primary mb-4">
      {{ selectedVaccineName }}, {{ prettyDate ?? '' | translate }}, {{ prettyTime }}
    </h4> -->

    <div
      id="terms-and-privacy"
      class="text-tertiary mb-4"
      [innerHTML]="safeTermsAndPrivacyHtml"
    ></div>

    <p
      class="text-tertiary pt-3"
      [innerHTML]="
        'APPOINTMENT.RESERVATION.SUBTITLE'
          | translate : { locationName: locationName }
      "
    ></p>

    <p class="text-danger" *ngIf="error">{{ error }}</p>
  </header>

  <div id="reservation-form-container">
    <!-- Loading indicator -->
    <app-loading-dynamic-form
      *ngIf="loading$ | async"
    ></app-loading-dynamic-form>

    <!-- Dynamic form -->
    <app-dynamic-form
      class="d-block"
      *ngIf="(loading$ | async) === false"
      [questions]="questions"
      [formData]="formData"
      (leftButtonClicked)="leftButtonClick()"
      (formSubmit)="submit($event)"
    ></app-dynamic-form>
  </div>
</main>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms()"
  (emitShowPrivacy)="showPrivacy()"
></app-terms-and-privacy-modal>
