<div class="m-0" role="radiogroup" [attr.aria-label]="listCategoryTC || '' | translate">
  <div
    *ngFor="let item of items"
    tabindex="-1"
    class="radio-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2"
    [ngClass]="{ selected: item.selected }"
    (click)="selectItem(item)"
    (keydown.space)="selectItem(item, $event)"
  >
    <label
      [for]="'list-item-' + item.code"
      class="d-flex flex-row justify-content-start align-items-center gap-2"
    >
      <input
        class="form-check-input m-0"
        type="radio"
        role="radio"
        [attr.aria-label]="(item.title | translate) + ' ' + (item.subtitle ?? '' | translate)"
        [name]="item.title | translate"
        [id]="'list-item-' + item.code"
        [checked]="item.selected"
        (click)="selectItem(item)"
      />
      <div aria-hidden="true" class="d-flex flex-column">
        <span class="m-0 text-primary">{{ item.title | translate }}</span>
        <span
          class="m-0 text-tertiary text-small"
          *ngIf="item.subtitle && (item.subtitle | translate).length > 0"
        >
          {{ item.subtitle | translate }}
        </span>
      </div>
    </label>
  </div>
</div>
