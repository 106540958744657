<main class="container pt-4 px-3" [appInert]="isAlertPromptVisible$ | async">
  <div class="d-flex gap-3">
    <div *ngIf="location.logoPath">
      <img aria-hidden="true" class="logo" [src]="location.logoPath" alt="" />
    </div>
    <div>
      <h1 class="m-0 text-primary">{{ location.locationName }}</h1>

      <app-location-address
        [address]="location.address"
        [distance]="location.distance"
        [city]="location.city"
        [state]="location.state"
      ></app-location-address>
    </div>
  </div>

  <app-location-contacts
    [location]="location"
    [hideDirections]="true"
    (openExternalUrl)="openExternalUrl($event)"
    (sendAnalyticsEvent)="sendAnalyticsEvent()"
  ></app-location-contacts>

  <ng-container
    *ngIf="
      location.bookingUrl ||
      hasAppointmentBookingUrl ||
      location.supportsApiScheduling
    "
  >
    <p class="text-tertiary mt-4 mb-2">
      {{ "APPOINTMENT.LOCATION.SUBTITLE" | translate }}
    </p>
    <h4 class="text-primary">{{ selectedVaccineName }}</h4>

    <h3
      *ngIf="location.supportsApiScheduling || hasAppointmentBookingUrl"
      class="text-uppercase text-tertiary text-semibold mt-4 mb-4"
    >
      {{ "APPOINTMENT.LOCATION.DETAIL_DATETIME_TITLE" | translate }}
    </h3>
  </ng-container>

  <app-date-picker
    *ngIf="location.supportsApiScheduling || hasAppointmentBookingUrl"
    [dates]="dates"
    [appointments]="filteredAppointments"
    [title]="'APPOINTMENT.LOCATION.DAYS' | translate"
    (selectedDate)="setStartDate($event)"
  ></app-date-picker>

  <app-time-picker
    *ngIf="location.supportsApiScheduling || hasAppointmentBookingUrl"
    [appointments]="filteredAppointments"
    [startDate]="startDate"
    [loading]="loading"
    [title]="'APPOINTMENT.LOCATION.TIMES' | translate"
    (selectedAppointmentTime)="selectedAppointmentTime($event)"
  ></app-time-picker>

  <app-loading-time-picker
    *ngIf="
      loading && (location.supportsApiScheduling || hasAppointmentBookingUrl)
    "
  ></app-loading-time-picker>

  <!--
    Location doesn't support backbone API scheduling
    Location has appointments but times do not have booking URL
  -->
  <div
    class="mt-4"
    *ngIf="
      !location.supportsApiScheduling &&
      !hasAppointmentBookingUrl &&
      location.bookingUrl &&
      appointments.length > 0
    "
  >
    <p class="text-uppercase text-tertiary text-small mb-0">
      {{
        "APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS"
          | translate : { appointmentsLength: appointments.length }
      }}
    </p>

    <ng-container
      *ngFor="
        let appointment of appointments.slice(0, 3);
        let last = last;
        index as i
      "
    >
      <span class="text-bold text-heading text-secondary">{{
        appointment.prettyTime
      }}</span>
      <span
        class="text-bold text-heading text-secondary"
        *ngIf="!appointment.isToday"
      >
        {{ getAppointmentDayOfWeek(appointment.date) }}
      </span>
      <span class="text-heading text-secondary" *ngIf="i <= 1 && !last"
        >,&nbsp;</span
      >
    </ng-container>

    <span
      class="text-heading text-secondary text-bold"
      *ngIf="appointments.length > 3"
    >
      {{ "APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS_OVERFLOW" | translate }}
    </span>
  </div>

  <!--
        Location doesn't support backbone API scheduling
        Location has appointments times with a bookingUrl or location has a bookingUrl
        Need to display the redirection disclaimer
      -->
  <div
    *ngIf="
      !location.supportsApiScheduling &&
      (hasAppointmentBookingUrl ||
        location.bookingUrl ||
        location.informationalUrl)
    "
    class="mt-3 pt-3 d-flex flex-column align-items-center"
  >
    <p class="text-secondary text-center">
      {{ "APPOINTMENT.LOCATION.REDIRECTION_DISCLAIMER" | translate }}
    </p>
  </div>

  <app-footer
    class="pt-4 d-block"
    [leftButtonName]="leftButtonName"
    (leftButtonClicked)="leftButtonClick()"
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="rightButtonDisabled"
    [rightButtonClass]="rightButtonClass"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</main>

<app-alert-prompt
  *ngIf="isAlertPromptVisible$ | async"
  [titleTC]="alertPrompt?.titleTC"
  [subtitleTC]="alertPrompt?.subtitleTC"
  [cancelTextTC]="alertPrompt?.cancelTextTC"
  [continueTextTC]="alertPrompt?.continueTextTC"
  (cancelAction)="alertPromptCancel()"
  (continueAction)="alertPromptContinue()"
></app-alert-prompt>
