import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ListSelectItem } from 'src/app/common/model/list-select';

@Component({
  selector: 'app-list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['./list-select.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSelectComponent {
  @Input() items: Array<ListSelectItem> = [];
  @Input() listCategoryTC?: string = '';
  @Output() itemSelected: EventEmitter<ListSelectItem | null> =
    new EventEmitter<ListSelectItem | null>();
  selectedItem: ListSelectItem | undefined;

  selectItem(item: ListSelectItem, event?: Event) {
    if (event) {
      event.preventDefault();
    }

    if (this.selectedItem === item) {
      item.selected = false;
      this.selectedItem = undefined;
    } else {
      this.items.forEach((i) => (i.selected = false));
      item.selected = true;
      this.selectedItem = item;
    }

    this.itemSelected.emit(this.selectedItem);
  }
}
