<main class="container py-4 px-3">
  <header class="mb-3">
    <h1 class="text-primary">{{ 'APPOINTMENT.CONFIRMATION.TITLE' | translate }}</h1>
    <p class="text-tertiary">{{ 'APPOINTMENT.CONFIRMATION.SUBTITLE' | translate }}</p>

    <h5 id="disclaimer" class="mb-4">
      {{
        'APPOINTMENT.CONFIRMATION.CANCEL_DISCLAIMER' | translate : { locationName: locationName }
      }}
    </h5>
  </header>

  <app-appointment-detail-card></app-appointment-detail-card>

  <app-footer
    [rightButtonName]="'APPOINTMENT.CONFIRMATION.DONE_BUTTON' | translate"
    [rightButtonLoading]="(loading$ | async) === true"
    [rightButtonDisabled]="(loading$ | async) === true"
    (rightButtonClicked)="done()"
  ></app-footer>
</main>
