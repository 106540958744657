import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
import { DateUtil } from './date';

export class FormValidatorUtil {
  /**
   * Birthday Validator
   *
   * The fromFormat method is used to parse the input value based on the "MM/dd/yyyy" format,
   * and the isValid property is checked to see if the parsed date is valid.
   * If the format is invalid, the validator returns an error with the key 'invalidFormat'.
   *
   * The validator checks if the parsed date is in the future using Luxon's DateTime.local() method
   * to get the current date and time, and compares it with the parsed date.
   * If the parsed date is in the future, the validator returns an error with the key 'futureDate'.
   * @returns
   */
  static dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isJsDate = DateUtil.dateIsJsDate(control.value); // can be a JS date if using the ngx-bootstrap date picker
      const value = isJsDate
        ? DateUtil.formatDateFromJsToMonthDayYear(control.value)
        : control.value;
      const isValidFormat = DateUtil.dateIsValidMonthDayYear(value); // Check if input value has a valid format

      if (!isValidFormat) {
        return { invalidDate: { value: control.value } }; // Return 'invalidDate' error if format is invalid
      }

      return null; // Return null if valid
    };
  }

  static minDateValidator(date: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const isJsDate = DateUtil.dateIsJsDate(control.value); // can be a JS date if using the ngx-bootstrap date picker
      const value = isJsDate
        ? DateUtil.formatDateFromJsToMonthDayYear(control.value)
        : control.value;
      const parsedDate = DateTime.fromFormat(value, 'MM/dd/yyyy', {
        locale: 'en-US',
      });
      const minDate = DateTime.fromFormat(date, 'yyyy-MM-dd', {
        locale: 'en-US',
      });
      // console.log(parsedDate, minDate);
      if (parsedDate <= minDate) {
        return { minDate: { value: value } };
      }

      return null; // Return null if valid
    };
  }

  static maxDateValidator(date: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const isJsDate = DateUtil.dateIsJsDate(control.value); // can be a JS date if using the ngx-bootstrap date picker
      const value = isJsDate
        ? DateUtil.formatDateFromJsToMonthDayYear(control.value)
        : control.value;
      const parsedDate = DateTime.fromFormat(value, 'MM/dd/yyyy', {
        locale: 'en-US',
      }).startOf('day');
      const maxDate = DateTime.fromFormat(date, 'yyyy-MM-dd', {
        locale: 'en-US',
      }).endOf('day');
      // console.log(parsedDate, maxDate);
      if (parsedDate >= maxDate) {
        return { maxDate: { value: value } };
      }

      return null; // Return null if valid
    };
  }

  /**
   * Email Validator
   *
   * The email validation is performed using a regular expression (emailRegex) that follows the common pattern for email validation.
   * The test method of the regular expression is used to check if the input value matches the pattern.
   * If the email is invalid, the validator returns an error with the key 'invalidEmail'
   * @returns
   */
  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Regular expression for email validation

      if (!emailRegex.test(value)) {
        return { invalidEmail: { value: control.value } }; // Return 'invalidEmail' error if email is invalid
      }

      return null; // Return null if valid
    };
  }

  /**
   * Phone Validator
   *
   * The phone validator uses regular expression (phoneRegex) to check for the xxx-xxx-xxxx format of a phone number.
   * The regular expression ^\d{3}-\d{3}-\d{4}$ matches three digits followed by a hyphen,
   * followed by another three digits and another hyphen, and finally followed by four digits.
   * The test method of the regular expression is used to check if the input value matches the pattern.
   * If the phone number is invalid, the validator returns an error with the key 'invalidPhone'.
   * @returns
   */
  static phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const phoneRegex = /^\d{3}-\d{3}-\d{4}$/; // Regular expression for xxx-xxx-xxxx phone number format validation

      if (!phoneRegex.test(value)) {
        return { invalidPhone: { value: control.value } }; // Return 'invalidPhone' error if phone number is invalid
      }

      return null; // Return null if valid
    };
  }
}
