<div class="d-flex flex-column gap-4">
  <div class="d-flex flex-column gap-2 placeholder-glow">
    <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-sm"></span>
    <span class="placeholder bg-light-gray col-12 placeholder-lg mb-1"></span>
  </div>

  <div class="d-flex flex-column gap-2 placeholder-glow">
    <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-sm"></span>
    <span class="placeholder bg-light-gray col-12 placeholder-lg mb-1"></span>
  </div>

  <div class="d-flex flex-column gap-2 placeholder-glow">
    <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-sm"></span>
    <span class="placeholder bg-light-gray col-12 placeholder-lg mb-1"></span>
  </div>

  <div class="placeholder-footer d-flex flex-row gap-2 justify-content-between placeholder-glow">
    <a class="btn btn-outline-primary btn-left disabled placeholder"></a>
    <a class="btn btn-primary btn-right disabled placeholder"></a>
  </div>
</div>
