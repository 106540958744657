import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInert]',
  standalone: true,
})
export class InertDirective {
  @Input() set appInert(value: boolean | null) {
    if (value) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'inert', 'true');
      this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', '-1');
      this.renderer.setAttribute(this.elementRef.nativeElement, 'aria-hidden', 'true');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'inert');
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'tabindex');
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'aria-hidden');
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
