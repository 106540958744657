import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from 'src/app/common/model/question/question-base';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneMaskDirective } from 'src/app/directives/phone-mask/phone-mask.directive';
import { DateMaskDirective } from 'src/app/directives/date-mask/date-mask.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DateUtil } from 'src/app/common/utils/date';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  getEmbedded,
  getEmbeddedAssetsUrl,
} from 'src/app/common/constants/general';

@Component({
  selector: 'app-dynamic-form-question',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PhoneMaskDirective,
    DateMaskDirective,
    BsDatepickerModule,
  ],
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormQuestionComponent {
  @Input() question!: QuestionBase<any>;
  @Input() form!: FormGroup;
  errorIconUrl = 'assets/icons/error.svg';

  get minDate(): Date | undefined {
    return this.question?.minValue
      ? DateUtil.formatMonthDayYearDateToJsDate(this.question.minValue)
      : undefined;
  }

  get maxDate(): Date | undefined {
    return this.question?.maxValue
      ? DateUtil.formatMonthDayYearDateToJsDate(this.question.maxValue)
      : undefined;
  }

  get minValueMonthDayYear(): string | undefined {
    return this.question?.minValue
      ? DateUtil.formatYearMonthDayToMonthDayYear(this.question.minValue)
      : undefined;
  }

  get maxValueMonthDayYear(): string | undefined {
    return this.question?.maxValue
      ? DateUtil.formatYearMonthDayToMonthDayYear(this.question.maxValue)
      : undefined;
  }

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }

  get isTouched() {
    return this.form.controls[this.question.key].touched;
  }

  constructor(private sanitizer: DomSanitizer) {
    if (getEmbedded()) {
      this.errorIconUrl = `${getEmbeddedAssetsUrl()}/assets/icons/error.svg`;
    }
  }

  getSafeHtml(htmlString: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  /**
   * We need this otherwise the value is never properly updated for checkbox types (checkbox, toggle)
   * @param event
   */
  checkboxValueChanged(event: any) {
    const { checked } = event.target;
    this.form.controls[this.question.key].setValue(checked);
    this.form.updateValueAndValidity();
  }

  /**
   * Update validity on change to accommodate browser auto-fill
   * @param event
   */
  textboxValueChanged(event: any) {
    this.form.controls[this.question.key].setValue(event.target.value);
    this.form.updateValueAndValidity();
  }

  dateValueChanged(event: any) {
    if (event && DateUtil.dateIsValidMonthDayYear(event.target.value)) {
      this.form.controls[this.question.key].setValue(event.target.value);
      this.form.updateValueAndValidity();
    } else {
      this.form.controls[this.question.key].setValue('');
      this.form.updateValueAndValidity();
    }
  }
}
