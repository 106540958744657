<main class="container py-4 px-3">
  <header class="mb-3">
    <h1 class="text-capitalize text-primary">{{ 'VACCINE.TITLE' | translate }}</h1>
    <p class="text-tertiary my-3">{{ selectedServiceName }} {{ 'VACCINE.LABEL' | translate }}</p>
  </header>

  <app-list-select [items]="items" (itemSelected)="setItem($event)"> </app-list-select>

  <app-footer
    [leftButtonName]="leftButtonName"
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="!selectedVaccine"
    (leftButtonClicked)="leftButtonClick()"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</main>
