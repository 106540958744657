<div class="card mt-4" aria-hidden="true">
  <div class="card-body">
    <div class="d-flex gap-3 align-items-center">
      <div class="d-flex flex-column gap-1 placeholder-text-block col-auto placeholder-glow">
        <span class="placeholder bg-light-gray placeholder-thumbnail-img"></span>
      </div>
      <div class="d-flex flex-column gap-2 placeholder-text-block col-8 col-md-5 placeholder-glow">
        <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-lg"></span>
        <div class="d-flex flex-row gap-3 col-12">
          <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-sm"></span>
          <span class="placeholder bg-light-gray col-1 placeholder-sm"></span>
        </div>
      </div>
    </div>

    <div class="d-flex gap-2 mt-4 placeholder-glow">
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
    </div>
  </div>
</div>

<div class="card mt-4" aria-hidden="true">
  <div class="card-body">
    <div class="d-flex gap-3 align-items-center">
      <div class="d-flex flex-column gap-1 placeholder-text-block col-auto placeholder-glow">
        <span class="placeholder bg-light-gray placeholder-thumbnail-img"></span>
      </div>
      <div class="d-flex flex-column gap-2 placeholder-text-block col-8 col-md-5 placeholder-glow">
        <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-lg"></span>
        <div class="d-flex flex-row gap-3 col-12">
          <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-sm"></span>
          <span class="placeholder bg-light-gray col-1 placeholder-sm"></span>
        </div>
      </div>
    </div>

    <div class="d-flex gap-2 mt-4 placeholder-glow">
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
      <a class="btn btn-primary disabled placeholder placeholder-time-button"></a>
    </div>
  </div>
</div>
