<div
  *ngIf="languages && languages.length > 1"
  id="language-picker"
  class="container py-2 px-3"
>
  <header class="d-flex align-items-center flex-column gap-4 mt-5">
    <svg
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Medium-M">
        <path
          id="Vector"
          d="M100.934 50.4117C100.934 78.0208 78.0872 100.824 50.4781 100.824C22.9038 100.824 0.0664062 78.0208 0.0664062 50.4117C0.0664062 22.8026 22.8604 0 50.4347 0C78.0438 0 100.934 22.8026 100.934 50.4117Z"
          fill="#2488D0"
        />
        <path
          id="Vector_2"
          d="M57.3317 91.442C63.533 91.442 80.6309 69.095 80.6309 60.7233C80.6309 56.0173 70.115 46.8081 64.6077 46.8081C62.1864 46.8081 58.3892 50.2332 55.5871 50.2332C52.8302 50.2332 46.6782 44.8074 46.6782 42.3723C46.6782 41.0381 47.7869 40.3118 49.1108 40.3118C51.4874 40.3118 55.2842 44.218 59.1152 44.218C62.2632 44.218 63.8571 42.0864 63.8571 39.0571C63.8571 37.3271 62.9467 35.593 61.6294 33.4592C66.6535 31.0314 76.5861 26.0298 79.8839 19.4148C76.3791 13.7253 66.7355 10 55.2859 10C38.5377 10 22.2796 19.9663 21.103 29.5228C20.6635 34.5716 24.1942 39.8923 30.9166 45.7007L38.0294 51.8789C40.2744 53.8731 41.5828 54.8007 41.5828 56.0468C41.5828 58.1763 39.1918 59.6018 39.1918 62.2809C39.1918 64.7542 40.3653 66.8 42.9009 68.6691C50.8423 74.6078 52.676 75.4381 52.676 77.2256C52.7383 80.4101 50.8172 82.8383 50.8172 85.8186C50.8172 88.4702 54.2873 91.442 57.3317 91.442Z"
          fill="#27AE60"
        />
      </g>
    </svg>
    <h2 class="text-primary text-center">{{ "LANGUAGE.TITLE" | translate }}</h2>
  </header>

  <section class="mb-4">
    <app-list-select
      [items]="getItems()"
      (itemSelected)="setItem($event)"
    ></app-list-select>
  </section>

  <app-footer
    [rightButtonName]="'LANGUAGE.BUTTON' | translate"
    [rightButtonLoading]="(loading$ | async) === true"
    [rightButtonDisabled]="!selectedLanguage"
    (rightButtonClicked)="setLanguage()"
  ></app-footer>
</div>
