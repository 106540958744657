export const environment = {
  name: 'dev',
  production: false,
  version: '1.3.3',
  apiBaseUrl: 'https://owowoqkmqdxaruy.easyvax.org/api',
  apiLocalStorageErrorKey: 'vax-dev', // update localStorage to include this key with a value of * to force API errors for testing
  apiKey: 'gf6:OAjCAzvaYYze', // API key for backbone
  recaptchaKey: '6LeRoQ8pAAAAABUHLjEtzM7LG-df0jVZ7rMswd8i',
  recaptchaAction: 'reserve',
};
