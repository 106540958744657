<main class="container py-4 px-3">
  <header class="mb-3">
    <h1 class="text-primary">{{ "APPOINTMENT.REVIEW.TITLE" | translate }}</h1>
  </header>

  <app-appointment-detail-card></app-appointment-detail-card>

  <p class="text-danger" *ngIf="recaptchaError">{{ recaptchaError }}</p>
  <p class="text-danger" *ngIf="error">{{ error }}</p>

  <app-footer
    [leftButtonName]="'BUTTON.BACK' | translate"
    [leftButtonDisabled]="(loading$ | async) === true"
    (leftButtonClicked)="leftButtonClick()"
    [rightButtonLoading]="(loading$ | async) === true"
    [rightButtonDisabled]="
      (loading$ | async) === true || recaptchaRetryCount >= recaptchaRetryLimit
    "
    [rightButtonName]="
      'APPOINTMENT.REVIEW.CONFIRM_APPOINTMENT_BUTTON' | translate
    "
    [rightButtonIncludeRecaptcha]="true"
    (rightButtonClicked)="rightButtonClick($event)"
  ></app-footer>

  <div
    id="recaptcha-privacy"
    class="text-tertiary mb-4 mt-4"
    [innerHTML]="safeRecaptchaHtml"
  ></div>
</main>
