<div class="h-100">
  <form
    (ngSubmit)="submit()"
    (keyup.enter)="submit()"
    *ngIf="form"
    [formGroup]="form"
    class="pb-2"
  >
    <div *ngFor="let question of questions" class="form-row">
      <app-dynamic-form-question
        class="d-block mt-3"
        [question]="question"
        [form]="form"
      >
      </app-dynamic-form-question>
    </div>
  </form>

  <app-footer
    class="d-block pt-4 pb-4"
    [leftButtonName]="leftButtonName"
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="!form.valid"
    (leftButtonClicked)="leftButtonClick()"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</div>
