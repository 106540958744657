<div
  class="card mb-4 position-relative"
  [attr.aria-labelledby]="'location-' + location.locationId"
>
  <!-- Card click block so the background is clickable -->
  <div class="click-block" (click)="viewLocationDetail.emit()"></div>

  <!-- Location Name and Address -->
  <div class="location-header d-flex gap-3" *ngIf="location">
    <div *ngIf="location?.logoPath">
      <img class="logo" [src]="location.logoPath" aria-hidden alt="" />
    </div>
    <div>
      <h1 [id]="'location-' + location.locationId" class="m-0 text-primary">
        {{ location.locationName }}
      </h1>

      <app-location-address
        [address]="location.address"
        [distance]="location.distance"
        [city]="location.city"
        [state]="location.state"
      ></app-location-address>
    </div>
  </div>

  <div #card class="card-body pt-0 position-relative">
    <!-- this will end up containing the html template from the components that extend this class -->
    <ng-content></ng-content>
  </div>

  <!-- Footer: used for Level 3 and 4, set at each integration level -->
  <div *ngIf="shouldShowLocationFooter" class="location-footer text-center">
    <button
      [attr.aria-label]="
        location.locationName +
        ' ' +
        ('APPOINTMENT.LOCATION.ADDITIONAL_TIMES_LABEL' | translate)
      "
      (click)="viewLocationDetail.emit()"
      class="btn btn-clear-primary text-normal p-0 m-0 text-capitalize z-2 position-relative"
    >
      {{ "APPOINTMENT.LOCATION.ADDITIONAL_TIMES_LABEL" | translate }}
    </button>
  </div>
</div>
