import { FormControl, FormGroup } from '@angular/forms';
import { QuestionBase } from '../../model/question/question-base';

export class QuestionControlService {
  toFormGroup(questions: QuestionBase<any>[]): FormGroup {
    const group: any = {};

    questions.forEach((question) => {
      group[question.key] = new FormControl(question.value, question.validators);
    });

    return new FormGroup(group);
  }
}
