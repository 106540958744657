import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { Observable, catchError, finalize, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AnalyticsEvent, AnalyticsEventRequest } from '../../model/event';
import { AppointmentService } from '../appointment/appointment.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private analyticsEvent: AnalyticsEvent | undefined = undefined;

  constructor(
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private http: HttpClient
  ) {}

  setAnalyticsEvent(event: AnalyticsEvent): void {
    this.analyticsEvent = event;
  }

  clearAnalyticsEvent() {
    this.analyticsEvent = undefined;
  }

  sendAnalyticsEvent(): void {
    const { apiBaseUrl, campaignId, selectedVaccine } = this.environmentService;
    const { reservation } = this.appointmentService;
    const { analyticsEvent } = this;

    if (analyticsEvent) {
      const analyticsEventRequest = new AnalyticsEventRequest(
        analyticsEvent?.eventType,
        analyticsEvent?.eventTimestamp,
        analyticsEvent?.trigger,
        campaignId,
        selectedVaccine?.vaccineCode as string,
        analyticsEvent?.location?.locationGroupId,
        analyticsEvent?.location?.locationId,
        analyticsEvent?.location?.locationName,
        analyticsEvent?.location?.address,
        analyticsEvent?.location?.city,
        analyticsEvent?.location?.state,
        analyticsEvent?.location?.zip,
        reservation?.date
      );

      const url = `${apiBaseUrl}/events`;
      this.http
        .post(url, analyticsEventRequest)
        .pipe(
          catchError((error) => {
            console.error('Error sending event:', error);
            return of(null); // Continue the observable chain
          }),
          finalize(() => {
            // Reset stored event after sending, regardless of success or error
            this.clearAnalyticsEvent();
          })
        )
        .subscribe();
    }
  }
}
