import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { QuestionBase } from '../../model/question/question-base';
import { SelectQuestion } from '../../model/question/question-select';
import { TextboxQuestion } from '../../model/question/question-textbox';
import { FormValidatorUtil } from '../../utils/form-validators';
import { Validators } from '@angular/forms';
import { Question } from '../../model/question/question';
import { CheckboxQuestion } from '../../model/question/question-checkbox';
import { HtmlQuestion } from '../../model/question/question-html';
import { ToggleQuestion } from '../../model/question/question-toggle';
import { DateQuestion } from '../../model/question/question-date';
import { NumberQuestion } from '../../model/question/question-number';

@Injectable()
export class QuestionService {
  getQuestions(respQuestions: Question[]): Observable<QuestionBase<any>[]> {
    const questions: QuestionBase<any>[] = [];
    const controlTypeMap: Record<string, any> = {
      textbox: TextboxQuestion,
      number: NumberQuestion,
      tel: TextboxQuestion,
      date: DateQuestion,
      email: TextboxQuestion,
      select: SelectQuestion,
      checkbox: CheckboxQuestion,
      toggle: ToggleQuestion,
      html: HtmlQuestion,
      postalcode: TextboxQuestion,
    };

    respQuestions.forEach((option) => {
      const ControlTypeClass = controlTypeMap[option.controlType];

      if (ControlTypeClass) {
        const validators = this.getValidators(option);

        let type: string;
        switch (option.controlType) {
          case 'tel':
            type = 'tel';
            break;
          case 'number':
            type = 'textbox';
            option.keyboard = 'numeric';
            break;
          case 'date':
            type = 'customDate';
            option.keyboard = 'numeric';
            break;
          case 'postalcode':
            type = 'textbox';
            option.keyboard = 'numeric';
            break;
          default:
            type = 'textbox';
        }
        const defaultValue = option.defaultValue ?? '';
        const question = new ControlTypeClass({
          key: option.key,
          labelTC: option.labelTC,
          placeholderTC: option.placeholderTC,
          keyboard: option.keyboard,
          value: defaultValue,
          minValue: option.minValue,
          maxValue: option.maxValue,
          validators: validators,
          type: type,
          options: option.options,
        });
        questions.push(question);
      }
    });

    return of(questions);
  }

  private getValidators(option: Question): any[] {
    const validators = [];
    if (option.required) {
      validators.push(Validators.required);

      if (option.controlType === 'checkbox' || option.controlType === 'radio') {
        validators.push(Validators.requiredTrue);
      }
    }
    if (option.minValue) {
      // check if this is a date control so we can either add the min date validator or the min validator
      if (option.controlType === 'date') {
        validators.push(FormValidatorUtil.minDateValidator(option.minValue));
      }
      if (option.controlType === 'number') {
        validators.push(Validators.min(Number(option.minValue)));
      }
    }
    if (option.maxValue) {
      // check if this is a date control so we can either add the max date validator or the max validator
      if (option.controlType === 'date') {
        validators.push(FormValidatorUtil.maxDateValidator(option.maxValue));
      }
      if (option.controlType === 'number') {
        validators.push(Validators.max(Number(option.maxValue)));
      }
    }
    if (option.maxLength) {
      validators.push(Validators.maxLength(option.maxLength));
    }
    if (option.minLength) {
      validators.push(Validators.minLength(option.minLength));
    }
    if (option.controlType === 'tel') {
      validators.push(FormValidatorUtil.phoneValidator());
    }
    if (option.controlType === 'date') {
      validators.push(FormValidatorUtil.dateValidator());
    }
    if (option.controlType === 'email') {
      validators.push(FormValidatorUtil.emailValidator());
    }
    return validators;
  }
}
