import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormQuestionComponent } from '../dynamic-form-question/dynamic-form-question.component';
import { QuestionBase } from 'src/app/common/model/question/question-base';
import { QuestionControlService } from 'src/app/common/services/question/question-control.service';
import { FooterComponent } from '../footer/footer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    FooterComponent,
  ],
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit {
  @Input() questions: QuestionBase<any>[] | null = [];
  @Input() formData?: any; // existing formData to try and autopopulate (in case of failed response)
  @Output() formSubmit = new EventEmitter<FormGroup>();
  @Output() leftButtonClicked = new EventEmitter<void>();
  form!: FormGroup;

  get leftButtonName(): string {
    return this.translateService.instant('BUTTON.BACK');
  }

  get rightButtonName(): string {
    return this.translateService.instant('APPOINTMENT.RESERVATION.NEXT_BUTTON');
  }

  constructor(
    private qcs: QuestionControlService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions as QuestionBase<any>[]);

    // populate form values if they are provided
    if (this.formData) {
      this.form.patchValue(this.formData);
      this.form.markAllAsTouched();
    }
  }

  leftButtonClick() {
    this.leftButtonClicked.emit();
  }

  rightButtonClick() {
    this.submit();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.formSubmit.emit(this.form);
  }
}
