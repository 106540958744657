<div
  *ngIf="languages && languages.length > 1 && !suppressSearchHeader"
  class="container py-2 px-3 d-flex gap-3 align-items-center justify-content-end"
>
  <button
    [attr.aria-label]="'LANGUAGE.ICON_BUTTON_ARIA_LABEL' | translate"
    class="btn btn-clear-primary btn-sm p-0 d-flex gap-2"
    (click)="presentLanguagePicker()"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-regular/world">
        <path
          id="Vector"
          d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM4 12C4 11.101 4.156 10.238 4.431 9.431L6 11L8 13V15L10 17L11 18V19.931C7.061 19.436 4 16.072 4 12ZM18.33 16.873C17.677 16.347 16.687 16 16 16V15C16 14.4696 15.7893 13.9609 15.4142 13.5858C15.0391 13.2107 14.5304 13 14 13H10V10C10.5304 10 11.0391 9.78929 11.4142 9.41421C11.7893 9.03914 12 8.53043 12 8V7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5V4.589C17.928 5.778 20 8.65 20 12C19.9998 13.7647 19.4123 15.4791 18.33 16.873Z"
          fill="currentColor"
        />
      </g>
    </svg>

    {{ formattedLanguage?.localeNativeTranslation }}
  </button>
</div>
