<main class="container py-4 px-3">
  <header class="mb-3">
    <h1 class="text-capitalize text-primary">
      {{ "SERVICE.TITLE" | translate }}
    </h1>

    <div class="row g-0" aria-hidden="true">
      <img
        class="hero d-block d-sm-none d-md-none d-lg-none d-xl-none"
        [src]="vaccinationArmSmallUrl"
        [alt]="'IMAGE.BANDAGE_ARM' | translate"
      />
      <img
        class="hero d-none d-sm-block d-md-block d-lg-block d-xl-block"
        [src]="vaccinationArmLargeUrl"
        [alt]="'IMAGE.BANDAGE_ARM' | translate"
      />
    </div>

    <p class="text-tertiary my-3">{{ "SERVICE.SUBTITLE" | translate }}</p>
  </header>

  <section class="pb-4" *ngFor="let service of services">
    <h2 class="text-uppercase text-primary pt-3 ps-3">
      {{ service.categoryNameTC | translate }}
    </h2>
    <app-list-select
      [items]="getItems(service)"
      [listCategoryTC]="service.categoryNameTC"
      (itemSelected)="setItem($event)"
    ></app-list-select>
  </section>

  <app-footer
    [rightButtonName]="rightButtonName"
    [rightButtonDisabled]="!selectedService"
    (rightButtonClicked)="rightButtonClick()"
  ></app-footer>
</main>

<div class="container text-center" *ngIf="!suppressTermsAndPrivacy">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <button class="btn btn-link-primary text-medium" (click)="showTerms()">
      {{ "APPOINTMENT.SEARCH.TERMS_CONDITIONS_BUTTON" | translate }}
    </button>

    <div class="divider"></div>

    <button class="btn btn-link-primary text-medium" (click)="showPrivacy()">
      {{ "APPOINTMENT.SEARCH.PRIVACY_POLICY_BUTTON" | translate }}
    </button>
  </div>
</div>

<app-footer-branding></app-footer-branding>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms($event)"
  (emitShowPrivacy)="showPrivacy($event)"
></app-terms-and-privacy-modal>
