<!-- Language Picker Modal -->
<app-language-picker
  *ngIf="
    (shouldShowLanguagePicker$ | async) &&
    languagePickerButtonEnabled &&
    !suppressSearchHeader
  "
></app-language-picker>

<!-- Language Picker Button that presents the modal -->
<app-language-picker-button
  *ngIf="
    shouldShowLanguagePickerButton &&
    languagePickerButtonEnabled &&
    !suppressSearchHeader
  "
></app-language-picker-button>

<router-outlet></router-outlet>
