export class QuestionBase<T> {
  value: T | undefined;
  minValue?: string;
  maxValue?: string;
  key: string;
  labelTC: string;
  placeholderTC: string;
  required?: boolean;
  validators?: any;
  controlType?: string;
  type?: string;
  keyboard?: string;
  options?: { value: string; labelTC: string }[];

  constructor(
    options: {
      value?: T;
      minValue?: string;
      maxValue?: string;
      key?: string;
      labelTC?: string;
      placeholderTC?: string;
      required?: boolean;
      validators?: any;
      controlType?: string;
      type?: string;
      keyboard?: string;
      options?: { value: string; labelTC: string }[];
    } = {}
  ) {
    this.value = options.value;
    this.minValue = options.minValue;
    this.maxValue = options.maxValue;
    this.key = options.key ?? '';
    this.labelTC = options.labelTC ?? '';
    this.placeholderTC = options.placeholderTC ?? options.labelTC ?? '';
    this.required = !!options.required;
    this.validators = options.validators;
    this.controlType = options.controlType ?? '';
    this.type = options.type ?? '';
    this.keyboard = options.keyboard ?? '';
    this.options = options.options ?? [];
  }
}
