import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from '../loading/loading.component';
import { Recaptcha } from 'src/app/common/model/recaptcha';
import { RecaptchaService } from 'src/app/common/services/recaptcha/recaptcha.service';
declare const grecaptcha: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, LoadingComponent],
})
export class FooterComponent implements AfterViewInit {
  @ViewChild('rightButton', { static: true }) rightButton!: ElementRef;

  @Input() leftButtonDisabled = false;
  @Input() leftButtonName?: string;
  @Input() rightButtonDisabled = false;
  @Input() rightButtonName?: string;
  @Input() rightButtonLoading? = false;
  @Input() rightButtonClass? = 'btn-primary';
  @Input() rightButtonIncludeRecaptcha? = false;
  @Output() leftButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() rightButtonClicked: EventEmitter<Recaptcha> =
    new EventEmitter<Recaptcha>();

  constructor(
    private renderer: Renderer2,
    private recaptchaService: RecaptchaService,
    private translateService: TranslateService
  ) {}

  leftButtonClick() {
    this.leftButtonClicked.emit();
  }

  async rightButtonClick() {
    if (this.rightButtonIncludeRecaptcha && typeof grecaptcha !== 'undefined') {
      // get the key and action from the environment service
      const { recaptchaKey, recaptchaAction } = this.recaptchaService;

      grecaptcha.enterprise.ready(async () => {
        try {
          const token = await grecaptcha.enterprise.execute(recaptchaKey, {
            action: recaptchaAction,
          });
          const event: Recaptcha = {
            token,
            action: recaptchaAction,
          };
          this.rightButtonClicked.emit(event);
        } catch (error: any) {
          this.recaptchaService.setError(
            this.translateService.instant('ERRORS.1004')
          );
        }
      });
    } else {
      this.rightButtonClicked.emit();
    }
  }

  ngAfterViewInit() {
    if (
      this.rightButtonIncludeRecaptcha &&
      this.rightButton &&
      typeof grecaptcha !== 'undefined'
    ) {
      this.setupRecaptchaAttributes();
    }
  }

  /**
   * Set recaptcha attributes to the right button (used on AppointmentReview)
   */
  private setupRecaptchaAttributes() {
    // get the key and action from the environment service
    const { recaptchaKey, recaptchaAction } = this.recaptchaService;

    // Set the Recaptcha Key
    this.renderer.setAttribute(
      this.rightButton.nativeElement,
      'data-sitekey',
      recaptchaKey
    );

    // Set the Recaptcha Callback
    this.renderer.setAttribute(
      this.rightButton.nativeElement,
      'data-callback',
      'rightButtonClick'
    );

    // Set the Recaptcha Action
    this.renderer.setAttribute(
      this.rightButton.nativeElement,
      'data-action',
      recaptchaAction
    );
  }
}
