import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListSelectItem } from '../common/model/list-select';
import { Service, ServiceGroup } from '../common/model/service';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { FooterComponent } from '../components/footer/footer.component';
import { ListSelectComponent } from '../components/list-select/list-select.component';
import { FooterBrandingComponent } from '../components/footer-branding/footer-branding.component';
import { RouteService } from '../common/services/route/route.service';
import { EventService } from '../common/services/event/event.service';
import { DateTime } from 'luxon';
import { AnalyticsEvent } from '../common/model/event';
import { TermsAndPrivacyModalComponent } from '../components/terms-and-privacy-modal/terms-and-privacy-modal.component';
import { ModalType } from '../common/types/modal-type';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  FADE_IN_ANIMATION,
  FADE_OUT_ANIMATION,
} from '../common/constants/animations';
import { Title } from '@angular/platform-browser';
import { getEmbedded, getEmbeddedAssetsUrl } from '../common/constants/general';

@Component({
  selector: 'app-service-selection',
  templateUrl: './service-selection.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ListSelectComponent,
    FooterComponent,
    TranslateModule,
    TermsAndPrivacyModalComponent,
    FooterBrandingComponent,
  ],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class ServiceSelectionComponent implements OnInit {
  get rightButtonName(): string {
    // TODO: will need to set this dynamically once we have more vaccines?
    return this.translateService.instant(
      'SERVICE.NEXT_STEP_APPOINTMENT_BUTTON'
    );
  }

  selectedService: Service | undefined;
  services: Array<ServiceGroup> | undefined = [];
  showTermsAndPrivacyModal = false;
  suppressTermsAndPrivacy = false;
  modalType: ModalType = 'prebooking-privacy';

  vaccinationArmSmallUrl = 'assets/vaccination-arm-sm.jpg';
  vaccinationArmLargeUrl = 'assets/vaccination-arm.jpg';

  constructor(
    private routeService: RouteService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private eventService: EventService,
    private titleService: Title
  ) {
    this.services = this.environmentService.configuration?.services;
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.VACCINES.TITLE')
      );

      this.vaccinationArmSmallUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm-sm.jpg`;
      this.vaccinationArmLargeUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm.jpg`;
    }

    this.suppressTermsAndPrivacy =
      this.environmentService.suppressTermsAndPrivacy;
  }

  public getItems(service: ServiceGroup) {
    return service.services.map((item) => {
      return new ListSelectItem(
        item.serviceNameTC,
        item.serviceDescriptionTC,
        this.selectedService?.serviceCode === item.serviceCode,
        item.serviceCode
      );
    });
  }

  public rightButtonClick() {
    let path = this.routeService.getVaccinesRoute();
    this.environmentService.selectedService = this.selectedService;

    // if we only have 1 vaccine for the selectedService pre-select it and skip vaccine selection
    if (this.selectedService?.vaccines.length === 1) {
      this.environmentService.selectedVaccine =
        this.selectedService?.vaccines[0];
      path = this.routeService.getAppointmentsRoute();
    }

    // Analytics event
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: 'vaccine-selected',
      eventTimestamp: eventTimestamp,
      trigger: 'vaccine-picker-navigation',
    };
    this.eventService.setAnalyticsEvent(analyticsEvent);
    this.eventService.sendAnalyticsEvent();

    const router = this.routeService.getRouter();
    router.navigate([path]);
  }

  public setItem(event: any) {
    if (event) {
      this.services?.forEach((service) => {
        const selectedService = service.services.find(
          (v) => v.serviceCode == event.code
        );
        if (selectedService) {
          this.selectedService = selectedService;
        }
      });
    } else {
      this.selectedService = undefined;
    }
  }

  /**
   * Terms and Privacy Modal Handling
   */
  dismissTermsAndPrivacyModal() {
    this.setShowTermsAndPrivacyModal(false);
  }

  // Shows or Hides the Privacy Policy or Terms and Conditions Modal
  // true: modal displays
  // false: modal hides
  setShowTermsAndPrivacyModal(shouldShow: boolean) {
    this.showTermsAndPrivacyModal = shouldShow;
  }

  setModalType(type: ModalType) {
    this.modalType = type;
  }

  /**
   * Opens the terms and conditions URL the server provided
   */
  showTerms(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-terms');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }

  showPrivacy(event?: boolean) {
    if (event) {
      this.dismissTermsAndPrivacyModal();
    }

    setTimeout(() => {
      this.setModalType('prebooking-privacy');
      this.setShowTermsAndPrivacyModal(true);
    }, 1);
  }
}
