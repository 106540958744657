<app-location-integration-level-base
  [location]="location"
  [shouldShowLocationFooter]="true"
  (viewLocationDetail)="viewLocationDetail.emit(location)"
  *ngIf="location"
>
  <!-- Results -->
  <div
    #appointmentRow
    class="appointment-row mt-4 d-inline-flex"
    *ngIf="filteredAppointments.length > 0"
  >
    <ng-container *ngFor="let appointment of filteredAppointments.slice(0, numAppointmentsPerRow)">
      <button
        [attr.aria-label]="appointment.prettyTime + ' ' + (appointment.prettyDate | translate)"
        class="btn d-flex flex-column align-items-center time-button btn-primary text-uppercase"
        (click)="selectedAppointmentTime(appointment)"
      >
        {{ appointment.prettyTime }}
        <span class="text-uppercase" aria-hidden="true">
          {{ appointment.prettyDate | translate }}
        </span>
      </button>
    </ng-container>
  </div>

  <!-- No Results -->
  <div *ngIf="filteredAppointments.length <= 0" class="d-flex flex-column gap-3">
    <p class="text-uppercase text-tertiary text-small mb-0 mt-4">
      {{ 'APPOINTMENT.LOCATION.NO_RESULTS' | translate }}
    </p>
  </div>
</app-location-integration-level-base>
