<div class="d-flex flex-column mt-4">
  <h4 class="text-bold text-primary pb-3">{{ title }}</h4>

  <!-- Allows user to set the startDate for location search -->
  <div id="times-container" class="d-flex flex-wrap justify-content-start gap-2">
    <ng-container *ngIf="appointments.length > 0">
      <ng-container *ngFor="let appointment of appointments">
        <button
          (click)="selectTime(appointment)"
          [innerText]="appointment.prettyTime"
          [class]="appointment.time === selectedStartTime ? 'btn-primary' : 'btn-outline-primary'"
          class="btn btn-sm d-flex flex-column flex-fill align-items-center text-uppercase justify-content-center"
        ></button>
      </ng-container>
    </ng-container>

    <!-- No appointment times available -->
    <ng-container *ngIf="noAppointments && !loading">
      <p class="text-uppercase text-tertiary text-small mb-0">
        {{ 'APPOINTMENT.LOCATION.DETAIL_NO_RESULTS' | translate }}
      </p>
    </ng-container>
  </div>
</div>
