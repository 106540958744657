import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppointmentDateTime,
  AppointmentSelection,
  AppointmentTimesResponse,
} from 'src/app/common/model/appointment';
import { DateUtil } from 'src/app/common/utils/date';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { Location } from 'src/app/common/model/location';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { FooterComponent } from '../components/footer/footer.component';
import { TimePickerComponent } from '../components/time-picker/time-picker.component';
import {
  DATE_TIME_PICKER_DEFAULT_WINDOW_DAYS,
  getEmbedded,
} from '../common/constants/general';
import { DateTime } from 'luxon';
import { DatePickerComponent } from '../components/date-picker/date-picker.component';
import { LocationAddressComponent } from '../components/location-address/location-address.component';
import { LocationContactsComponent } from '../components/location-contacts/location-contacts.component';
import { LoadingTimePickerComponent } from '../components/loading-time-picker/loading-time-picker.component';
import { AlertPromptComponent } from '../components/alert-prompt/alert-prompt.component';
import { EventService } from '../common/services/event/event.service';
import {
  AnalyticsEventTriggerType,
  AnalyticsEventType,
} from '../common/types/track-event-type';
import { AlertPromptExternalUrlService } from '../common/services/alert-prompt/alert-prompt-external-url.service';
import { AlertPromptBase } from '../common/model/alert-prompt/alert-prompt-base';
import { AnalyticsEvent } from '../common/model/event';
import { AlertPromptType } from '../common/types/alert-prompt-type';
import { AlertPromptDirections } from '../common/model/alert-prompt/alert-prompt-directions';
import { AlertPromptPharmacyWebsite } from '../common/model/alert-prompt/alert-prompt-pharmacy-website';
import { InertDirective } from '../directives/inert/inert.directive';
import { RouteService } from '../common/services/route/route.service';
import { DateOption } from '../common/model/picker';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-location-detail',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    TimePickerComponent,
    DatePickerComponent,
    LocationAddressComponent,
    LocationContactsComponent,
    LoadingTimePickerComponent,
    AlertPromptComponent,
    InertDirective,
  ],
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
})
export class LocationDetailComponent implements OnDestroy, OnInit {
  appointments: AppointmentDateTime[] = []; // location.appointments and times mapped to a new class for easy use in the template
  dates: DateOption[] = [];
  filteredAppointments: AppointmentDateTime[] = [];
  hasAppointmentBookingUrl = false;
  isAlertPromptVisible$: Observable<boolean>;
  loading = false;
  localeSubscription: Subscription = new Subscription();
  location!: Location;
  schedulingWindowDays: number;
  selectedAppointment: AppointmentSelection | undefined;
  selectedVaccineName?: string;
  startDate: string;
  startTime: string;

  get leftButtonName(): string {
    return this.translateService.instant('BUTTON.BACK');
  }

  get rightButtonName(): string {
    if (this.location.supportsApiScheduling || this.hasAppointmentBookingUrl) {
      return this.translateService.instant(
        'APPOINTMENT.LOCATION.DETAIL_NEXT_BUTTON'
      );
    } else if (this.location.bookingUrl) {
      return this.translateService.instant(
        'APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_BUTTON'
      );
    } else if (this.location.informationalUrl) {
      return this.translateService.instant(
        'APPOINTMENT.LOCATION.INFORMATIONAL_WEBSITE_BUTTON'
      );
    }
    return '';
  }

  get rightButtonClass(): string {
    if (this.location.supportsApiScheduling || this.hasAppointmentBookingUrl) {
      return 'btn-primary';
    }
    return 'btn-outline-primary';
  }

  get rightButtonDisabled() {
    return (
      (this.location.supportsApiScheduling || this.hasAppointmentBookingUrl) &&
      this.selectedAppointment === undefined
    );
  }

  get alertPrompt(): AlertPromptBase<any> | undefined {
    return this.alertPromptExternalUrlService.alertPrompt;
  }

  constructor(
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private routeService: RouteService,
    private translateService: TranslateService,
    private alertPromptExternalUrlService: AlertPromptExternalUrlService,
    private eventService: EventService,
    private titleService: Title
  ) {
    this.isAlertPromptVisible$ =
      this.alertPromptExternalUrlService.getAlertPromptVisibility();
    this.startDate = this.appointmentService.startDate;
    this.startTime = this.appointmentService.startTime;

    this.schedulingWindowDays =
      this.environmentService.configuration?.schedulingWindowDays ??
      DATE_TIME_PICKER_DEFAULT_WINDOW_DAYS;

    this.location = appointmentService.selectedLocation as Location;

    // clear the appointments because we're about to fetch more for the future
    // if we don't do this it will flash with the "today" appointments from the location search
    // and then display the full list
    this.location.appointments = [];

    this.getAppointmentTimes();
    this.configureDates();

    // listen for locale changed so we can update the time UI with the proper locale format
    this.localeSubscription = this.environmentService.localeChanged.subscribe(
      () => {
        // retranslate vaccine instant translations if the locale changes
        this.setSelectedVaccineTranslations();

        // reset so dates and times are in proper locale
        this.configureDates();
        this.setAppointmentTimes();
      }
    );
  }

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.PHARMACY_DETAIL.TITLE')
      );
    }

    this.setAppointmentTimes();
    this.setSelectedVaccineTranslations();
  }

  ngOnDestroy(): void {
    if (this.localeSubscription) {
      this.localeSubscription.unsubscribe();
    }
  }

  configureDates() {
    // Get current date
    const currentDate = DateTime.now();

    // Create an array to store dates
    const dates: DateOption[] = [];

    // Loop through days from current date
    // Uses schedulingWindowDays returned by the config response otherwise it uses the default
    for (let i = 0; i < this.schedulingWindowDays; i++) {
      // Add current date plus i days to the array
      const date = currentDate.plus({ days: i });

      // Format the date label
      const dayLabel = date.toFormat('EEE');
      const dateLabel = date.toFormat('d');

      // Push the date and label to the dates array
      dates.push({
        date: date.toFormat('yyyy-MM-dd'),
        dayLabel: dayLabel,
        dateLabel: dateLabel,
      });
    }

    this.dates = dates;
  }

  getAppointmentDayOfWeek(date: string): string {
    return DateUtil.formatDayOfWeek(date);
  }

  async getAppointmentTimes() {
    this.loading = true;

    const { locationId } = this.location;
    const { startDate } = this;
    const { schedulingWindowDays } = this;

    const formattedStartDate = DateTime.fromISO(startDate);
    const formattedEndDate = formattedStartDate.plus({
      days: schedulingWindowDays - 1,
    });
    const endDate = formattedEndDate.toFormat('yyyy-MM-dd');

    return this.appointmentService
      .getAppointmentTimes(locationId, endDate)
      .subscribe({
        next: (appointmentTimes: AppointmentTimesResponse) => {
          if (appointmentTimes) {
            // Set the appointments on the location
            this.location.appointments = appointmentTimes.appointments;

            // Map the new appointment times
            this.setAppointmentTimes();

            this.loading = false;
          }
        },
        error: (error: any) => {
          // on error we clear location appointments
          this.location.appointments = [];
          // calling this will clear the appointments used in the template
          this.setAppointmentTimes();

          this.loading = false;
        },
      });
  }

  /**
   * Takes all of the location appointments and maps them into one array so we can slice it for the card display
   */
  setAppointmentTimes() {
    const appointmentDateTimes: AppointmentDateTime[] = [];

    if (this.location?.appointments) {
      this.location.appointments.forEach((appointment) => {
        const { date } = appointment;
        appointment.times.forEach((time) => {
          // need to mark that we have appointment bookingUrl so the appointment website button is hidden
          if (time.bookingUrl) {
            this.hasAppointmentBookingUrl = true;
          }

          appointmentDateTimes.push(
            new AppointmentDateTime(
              time.appointmentId,
              date,
              time.time,
              DateUtil.formatDate(date),
              DateUtil.formatTime(time.time),
              DateUtil.dateIsToday(date),
              time.bookingUrl ?? '',
              time.duration
            )
          );
        });
      });
    }

    this.appointments = appointmentDateTimes;
    this.filterAppointmentDateTimes(appointmentDateTimes);
  }

  private setSelectedVaccineTranslations() {
    const { selectedVaccineName } = this.environmentService;
    if (selectedVaccineName) {
      this.selectedVaccineName =
        this.translateService.instant(selectedVaccineName);
    }
  }

  setStartDate(event: any) {
    this.startDate = event;
    this.selectedAppointment = undefined; // if start date changes we need to clear any previous selectedAppointment
    this.setAppointmentTimes(); // set times for the new date
  }

  filterAppointmentDateTimes(appointmentDateTimes: AppointmentDateTime[]) {
    const { startTime, startDate } = this;
    // Convert the filtered date and time to a Luxon DateTime object
    const isoDate = `${startDate}T${startTime}`;
    const filterDateTime = DateUtil.formatDateFromIso(isoDate);
    const now = DateUtil.currentDateUTC;

    this.filteredAppointments = appointmentDateTimes.filter((appointment) => {
      // if the selected startDate is in the future display all appointment times for that date
      if (
        appointment.date === this.startDate &&
        !DateUtil.dateIsToday(this.startDate)
      ) {
        return true;
      }

      const appointmentTimeObj = DateUtil.formatDateFromIso(appointment.time);
      // if the selected startDate is today filter appointments in the past
      return (
        appointmentTimeObj >= filterDateTime &&
        appointmentTimeObj >= now &&
        appointment.date == this.startDate
      );
    });
  }

  selectedAppointmentTime(appointment: AppointmentDateTime | undefined) {
    if (!appointment) {
      this.selectedAppointment = undefined;
    } else {
      const { location } = this;

      const appointmentSelection: AppointmentSelection = {
        location: location,
        locationId: location.locationId,
        appointmentId: appointment.appointmentId,
        date: appointment.date,
        time: appointment.time,
        supportsApiScheduling: location.supportsApiScheduling,
        appointmentBookingUrl: appointment.bookingUrl,
        locationBookingUrl: location.bookingUrl ?? '',
        duration: appointment.duration,
      };

      this.selectedAppointment = appointmentSelection;
    }
  }

  public doSelectAppointment() {
    const event = this.selectedAppointment;

    /**
     * If supportsApiScheduling is false for the location, the url to which the user
     * should be redirected in order to schedule this appointment slot (if api scheduling is not supported)
     */
    if (event?.supportsApiScheduling === false) {
      const externalBookingUrl =
        event?.appointmentBookingUrl !== ''
          ? event?.appointmentBookingUrl
          : event?.locationBookingUrl;

      // set analytics event and prompt user asking if they want to navigate away
      if (externalBookingUrl) {
        this.setAnalyticsEvent('website-transfer', 'appointment-booking-url');
        this.setAlertPrompt('pharmacy-website');
        this.openExternalUrl(externalBookingUrl);
      }
      return;
    }

    /**
     * Supports backbone API scheduling
     */
    // Set the appointment for our API scheduling
    this.appointmentService.selectedAppointment = event;

    // Route to reservation screen
    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getReservationRoute()]);
  }

  public leftButtonClick() {
    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getAppointmentsRoute()]);
  }

  public rightButtonClick() {
    const { location } = this;
    if (location.supportsApiScheduling || this.hasAppointmentBookingUrl) {
      // if this location supportsApiScheduling select the appointment
      this.doSelectAppointment();
    } else if (location.bookingUrl) {
      // set analytics event and prompt user asking if they want to navigate away
      this.setAnalyticsEvent('website-transfer', 'location-booking-url');
      this.setAlertPrompt('pharmacy-website');
      this.openExternalUrl(location.bookingUrl);
    } else if (location.informationalUrl) {
      // set analytics event and prompt user asking if they want to navigate away
      this.setAnalyticsEvent('website-transfer', 'informational-url');
      this.setAlertPrompt('pharmacy-website');
      this.openExternalUrl(location.informationalUrl);
    }
  }

  /**
   * Analytics Event Handling
   */
  setAnalyticsEvent(
    eventType: AnalyticsEventType,
    trigger: AnalyticsEventTriggerType
  ) {
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: eventType,
      eventTimestamp: eventTimestamp,
      trigger: trigger,
      location: this.location,
    };

    this.eventService.setAnalyticsEvent(analyticsEvent);
  }

  sendAnalyticsEvent() {
    this.eventService.sendAnalyticsEvent();
  }

  /**
   * Alert Prompt Handling
   */
  public alertPromptCancel() {
    this.hideAlertPrompt();
  }

  public alertPromptContinue() {
    this.alertPromptExternalUrlService.continueToExternalUrl();

    this.eventService.sendAnalyticsEvent();

    this.hideAlertPrompt();
  }

  public openExternalUrl(url: string) {
    this.alertPromptExternalUrlService.openAlertPrompt(url);
  }

  /**
   * Alert Prompt Handling
   */
  public setAlertPrompt(type: AlertPromptType) {
    let prompt: AlertPromptBase<any> | undefined;
    switch (type) {
      case 'directions':
        prompt = new AlertPromptDirections();
        break;
      case 'pharmacy-website':
        prompt = new AlertPromptPharmacyWebsite();
        break;
      default:
        break;
    }
    if (prompt) {
      this.alertPromptExternalUrlService.setAlertPrompt(prompt);
    }
  }

  private hideAlertPrompt() {
    this.alertPromptExternalUrlService.dismissAlertPrompt();

    this.eventService.clearAnalyticsEvent();
  }
}
