import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { AppointmentDetailCardComponent } from 'src/app/components/appointment-detail-card/appointment-detail-card.component';
import { AppointmentReservationResponse } from 'src/app/common/model/appointment';
import { RouteService } from 'src/app/common/services/route/route.service';
import { Recaptcha } from 'src/app/common/model/recaptcha';
import { VaxError } from 'src/app/common/model/error';
import { RecaptchaService } from 'src/app/common/services/recaptcha/recaptcha.service';
import {
  ServiceErrorCodes,
  getEmbedded,
} from 'src/app/common/constants/general';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-appointment-review',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    LoadingComponent,
    AppointmentDetailCardComponent,
  ],
  templateUrl: './appointment-review.component.html',
  styleUrls: ['./appointment-review.component.scss'],
})
export class AppointmentReviewComponent implements OnInit, OnDestroy {
  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;
  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
  }
  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  safeRecaptchaHtml: SafeHtml = '';
  recaptchaRetryLimit = this.recaptchaService.recaptchaRetryLimit;
  get recaptchaRetryCount() {
    return this.recaptchaService.recaptchaRetryCount;
  }
  get recaptchaError() {
    const err = <any>this.recaptchaService.recaptchaError;

    if (!err) {
      return '';
    }

    if (err.details && err.details.length > 0) {
      return err.details[0].reason;
    }

    return '';
  }

  get error() {
    return this.appointmentService.error;
  }

  constructor(
    private appointmentService: AppointmentService,
    private routeService: RouteService,
    private recaptchaService: RecaptchaService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    this.loading$ = this.loadingObj.asObservable();

    // Recaptcha Terms and Conditions handling
    const translatedRecaptchaHtml = this.translateService.instant(
      'APPOINTMENT.REVIEW.RECAPTCHA_DISCLAIMER'
    );
    this.safeRecaptchaHtml = this.sanitizer.bypassSecurityTrustHtml(
      translatedRecaptchaHtml
    );
  }

  ngOnInit(): void {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.REVIEW.TITLE')
      );
    }
  }

  leftButtonClick() {
    this.appointmentService.clearError();

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getReservationRoute()]);
  }

  /**
   *
   * @param event The ReCaptcha token that needs to be included in the reserveAppointment request
   */
  rightButtonClick(event: Recaptcha) {
    this.loading = true;

    this.appointmentService.clearError();

    // Only allow a certain amount of recaptcha retries
    if (this.recaptchaRetryCount >= this.recaptchaRetryLimit) {
      return;
    }

    // Set the recaptcha token
    if (event && this.appointmentService.reservation) {
      this.appointmentService.reservation.token = event.token;
      this.appointmentService.reservation.action = event.action;
    }

    // Call reserve
    this.appointmentService?.reserveAppointment()?.subscribe({
      next: (resp: AppointmentReservationResponse) => {
        this.recaptchaService.clearError();

        this.loading = false;

        if (this.appointmentService.reservation && resp?.confirmationCode) {
          this.appointmentService.reservation.confirmationCode =
            resp.confirmationCode;
        }

        const router = this.routeService.getRouter();
        router.navigate([this.routeService.getConfirmationRoute()]);
      },
      error: (error: any) => {
        this.loading = false;
        this.handleServiceError(error.error);
      },
    });
  }

  ngOnDestroy(): void {
    this.recaptchaService.clearError();
  }

  handleServiceError(error: VaxError) {
    console.log('Vax Error being handled: ', error);
    let msg = '';

    switch (error.subCode) {
      case ServiceErrorCodes.SUBCODE_VALIDATION_ERROR:
        msg = this.translateService.instant('ERRORS.1000.MESSAGE');
        msg +=
          ' (' + error.details[0].name + ': ' + error.details[0].reason + ')';

        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_APPOINTMENT_NOT_AVAILABLE:
        msg = this.translateService.instant('ERRORS.1001.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_RETAILER_ERROR:
        msg = this.translateService.instant('ERRORS.1002.MESSAGE');
        msg +=
          ' (' + error.details[0].name + ': ' + error.details[0].reason + ')';
        this.appointmentService.setError(msg);
        break;
      // 1003 = Invalid search address
      case ServiceErrorCodes.SUBCODE_RECAPTCHA_ERROR:
        msg = this.translateService.instant('ERRORS.1004.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PRESCRIPTION_REQUIRED_ERROR:
        msg = this.translateService.instant('ERRORS.1005.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_SEASONAL_VACCINE_NOT_AVAILABLE:
        msg = this.translateService.instant('ERRORS.1006.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PATIENT_TOO_YOUNG:
        msg = this.translateService.instant('ERRORS.1007.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      case ServiceErrorCodes.SUBCODE_PATIENT_INELIGIBLE:
        msg = this.translateService.instant('ERRORS.1008.MESSAGE');
        this.appointmentService.setError(msg);
        break;
      default:
        this.appointmentService.setError(error.details[0].reason);
        break;
    }
  }
}
