<div [formGroup]="form">
  <label
    *ngIf="
      question.controlType === 'textbox' ||
      question.controlType === 'select' ||
      question.controlType === 'date'
    "
    class="text-secondary mb-1"
    [attr.for]="question.key"
  >
    {{ question.labelTC | translate }}
  </label>

  <div class="position-relative" [ngSwitch]="question.controlType">
    <!-- TEXTBOX -->
    <input
      *ngSwitchCase="'textbox'"
      class="form-control text-primary mb-2 text-semibold"
      [attr.inputmode]="question.keyboard ? question.keyboard : null"
      [attr.aria-describedby]="question.key + '-error'"
      [class.error]="!isValid && isTouched"
      tabindex="0"
      autocomplete="on"
      (change)="textboxValueChanged($event)"
      [appPhoneMask]="question.type === 'tel'"
      [placeholder]="question.placeholderTC | translate"
      [formControlName]="question.key"
      [id]="question.key"
      [type]="question.type"
    />
    <img
      class="error-icon z-1"
      aria-hidden="true"
      *ngIf="!isValid && isTouched"
      [class.me-5]="question.controlType === 'date'"
      [class.me-4]="
        question.controlType === 'select' || question.type === 'number'
      "
      [src]="errorIconUrl"
      [alt]="'ICON.ERROR' | translate"
    />

    <!-- DATE -->
    <div class="input-group" *ngSwitchCase="'date'">
      <input
        class="form-control custom-primary-color text-primary mb-2 text-semibold"
        [class.error]="!isValid && isTouched"
        tabindex="0"
        (change)="dateValueChanged($event)"
        #dp="bsDatepicker"
        bsDatepicker
        autocomplete="off"
        [attr.inputmode]="question.keyboard ? question.keyboard : null"
        [bsConfig]="{
          containerClass: 'custom-primary-color',
          dateInputFormat: 'MM/DD/YYYY',
          showWeekNumbers: false,
          returnFocusToInput: true,
          startView: form.get(question.key)?.value ? 'day' : 'year',
          minDate: minDate,
          maxDate: maxDate
        }"
        [appDateMask]="question.type === 'customDate'"
        [placeholder]="question.placeholderTC | translate"
        [formControlName]="question.key"
        triggers=""
        [id]="question.key"
        [type]="question.type"
        [attr.aria-describedby]="question.key + '-error calendar-button'"
      />
      <button
        (click)="dp.toggle()"
        class="btn btn-outline-primary mb-2"
        type="button"
        id="calendar-button"
        [attr.aria-label]="'APPOINTMENT.RESERVATION.CALENDAR' | translate"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 11H9V13H7V11ZM7 15H9V17H7V15ZM11 11H13V13H11V11ZM11 15H13V17H11V15ZM15 11H17V13H15V11ZM15 15H17V17H15V15Z"
            fill="currentColor"
          />
          <path
            d="M5 22H19C20.103 22 21 21.103 21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22ZM19 8L19.001 20H5V8H19Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>

    <!-- SELECT -->
    <select
      class="form-select text-primary mb-2 text-semibold"
      *ngSwitchCase="'select'"
      [class.error]="!isValid && isTouched"
      [id]="question.key"
      [formControlName]="question.key"
      [attr.aria-describedby]="question.key + '-error'"
      tabindex="0"
    >
      <option value="" selected>
        {{ question.placeholderTC | translate }}
      </option>
      <option *ngFor="let opt of question.options" [value]="opt.value">
        {{ opt.labelTC | translate }}
      </option>
    </select>

    <!-- TOGGLE -->
    <div *ngSwitchCase="'toggle'" class="form-check form-switch">
      <label class="form-check-label" [for]="question.key">{{
        question.placeholderTC
      }}</label>
      <input
        class="form-check-input toggle text-primary text-semibold"
        role="switch"
        [type]="question.type"
        [formControlName]="question.key"
        [id]="question.key"
        (change)="checkboxValueChanged($event)"
        [attr.aria-checked]="form.controls[question.key].value"
        [attr.aria-label]="question.labelTC | translate"
        [attr.aria-describedby]="question.key + '-error'"
        autocomplete="off"
      />
    </div>

    <!-- CHECKBOX -->
    <div
      *ngSwitchCase="'checkbox'"
      class="d-flex flex-row gap-3 align-items-center"
    >
      <input
        class="form-check-input text-primary text-semibold m-0"
        [class.error]="!isValid && isTouched"
        style="flex: none"
        role="checkbox"
        [type]="question.type"
        [formControlName]="question.key"
        [id]="question.key"
        [checked]="form.controls[question.key].value ? true : false"
        (change)="checkboxValueChanged($event)"
        [attr.aria-checked]="form.controls[question.key].value"
        [attr.aria-label]="question.labelTC | translate"
        [attr.aria-describedby]="question.key + '-error'"
      />
      <label class="text-secondary pe-5" [for]="question.key">
        {{ question.labelTC | translate }}
      </label>
    </div>

    <!-- HTML -->
    <div
      class="text-primary"
      *ngSwitchCase="'html'"
      [innerHtml]="getSafeHtml(question.labelTC | translate)"
    ></div>
  </div>

  <!-- CLIENT-SIDE VALIDATION ERRORS -->
  <p
    [id]="question.key + '-error'"
    role="alert"
    class="text-danger d-flex flex-column mb-2"
    *ngIf="!isValid && isTouched"
  >
    <span *ngIf="form.get(question.key)?.hasError('required')">
      {{ question.labelTC | translate }}
      {{ "APPOINTMENT.RESERVATION.VALIDATION_REQUIRED" | translate }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('invalidDate')">
      {{ "APPOINTMENT.RESERVATION.VALIDATION_INVALID_DATE" | translate }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('invalidEmail')">
      {{ "APPOINTMENT.RESERVATION.VALIDATION_INVALID_EMAIL" | translate }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('invalidPhone')">
      {{ "APPOINTMENT.RESERVATION.VALIDATION_INVALID_PHONE" | translate }}
    </span>

    <span
      *ngIf="form.get(question.key)?.hasError('maxDate') && !question.minValue"
    >
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MAX_DATE_VALUE"
          | translate : { formattedMaxValue: maxValueMonthDayYear }
      }}
    </span>

    <span
      *ngIf="form.get(question.key)?.hasError('minDate') && !question.maxValue"
    >
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MIN_DATE_VALUE"
          | translate : { formattedMinValue: minValueMonthDayYear }
      }}
    </span>

    <span
      *ngIf="
        (form.get(question.key)?.hasError('minDate') ||
          form.get(question.key)?.hasError('maxDate')) &&
        question.minValue &&
        question.maxValue
      "
    >
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MIN_AND_MAX_DATE_VALUE"
          | translate
            : {
                formattedMinValue: minValueMonthDayYear,
                formattedMaxValue: maxValueMonthDayYear
              }
      }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('max') && !question.minValue">
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MAX_VALUE"
          | translate : { maxValue: question.maxValue }
      }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('min') && !question.maxValue">
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MIN_VALUE"
          | translate : { minValue: question.minValue }
      }}
    </span>

    <span
      *ngIf="
        (form.get(question.key)?.hasError('min') ||
          form.get(question.key)?.hasError('max')) &&
        question.minValue &&
        question.maxValue
      "
    >
      {{
        "APPOINTMENT.RESERVATION.VALIDATION_MIN_AND_MAX_VALUE"
          | translate
            : { minValue: question.minValue, maxValue: question.maxValue }
      }}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('maxlength')">
      {{ "APPOINTMENT.RESERVATION.VALIDATION_MAX_LENGTH" | translate }}
      {{ '(' + form.get(question.key)?.errors?.['maxlength'].actualLength + '/' + form.get(question.key)?.errors?.['maxlength'].requiredLength + ')'}}
    </span>

    <span *ngIf="form.get(question.key)?.hasError('minlength')">
      {{ "APPOINTMENT.RESERVATION.VALIDATION_MIN_LENGTH" | translate }}
      {{ '(' + form.get(question.key)?.errors?.['minlength'].actualLength + '/' + form.get(question.key)?.errors?.['minlength'].requiredLength + ')'}}
    </span>
  </p>
</div>
